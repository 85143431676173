<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/locations">Standorte</router-link></li>
              <li>{{ location.name }}</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/locations" class="button button-grey button-small">Alle Standorte ›</router-link></p>
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/locations/'+this.$route.params.id">Stammdaten</router-link></li>
            <li class="active"><router-link :to="'/admin/locations/'+this.$route.params.id+'/hours'">Öffnungszeiten</router-link></li>
            <li><router-link :to="'/admin/locations/'+this.$route.params.id+'/users'">Mitarbeiter</router-link></li>
          </ul>


          <table class="table">
            <thead>
              <tr>
                <th width="45%">Tag</th>
                <th width="45%">Uhrzeit</th>
                <th width="10%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="hour in hours" v-bind:key="hour.id">
                <td>
                  <span v-if="hour.hour_day == 'monday'">Montag</span>
                  <span v-if="hour.hour_day == 'tuesday'">Dienstag</span>
                  <span v-if="hour.hour_day == 'wednesday'">Mittwoch</span>
                  <span v-if="hour.hour_day == 'thursday'">Donnerstag</span>
                  <span v-if="hour.hour_day == 'friday'">Freitag</span>
                  <span v-if="hour.hour_day == 'saturday'">Samstag</span>
                  <span v-if="hour.hour_day == 'sunday'">Sonntag</span>
                </td>
                <td>{{ hour.hour_time_formatted }} Uhr</td>
                <td style="text-align:right">
                  <a @click="delete_hour(hour)" href="#">
                    <span class="material-icons">delete</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <hr>

          <form @submit="create_hour" class="form" method="post">

            <div class="form-wrap select-wrap">
              <label for="street_number">Tag</label>
              <select v-model="hour.day" class="select-wrap">
                <option value="monday">Montag</option>
                <option value="tuesday">Dienstag</option>
                <option value="wednesday">Mittwoch</option>
                <option value="thursday">Donnerstag</option>
                <option value="friday">Freitag</option>
                <option value="saturday">Samstag</option>
                <option value="sunday">Sonntag</option>
              </select>
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="form-wrap">
                  <label for="from">Uhrzeit Von</label>
                  <input v-model="hour.from" id="from" class="form-input" placeholder="08">
                </div>
              </div>
              <div class="col-12">
                <div class="form-wrap">
                  <label for="to">Uhrzeit Bis</label>
                  <input v-model="hour.to" id="to" class="form-input" placeholder="18">
                </div>
              </div>
            </div>

            <p>
              <input type="submit" value="Öffnungszeiten hinzufügen ›" class="button button-orange button-small">
            </p>

          </form>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'
//import moment from 'moment'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      errors: [],
      location: {},
      hours: [],
      hour: {}
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.location = response.data.location;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations/'+this.$route.params.id+'/business_hours', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.hours = response.data.business_hours;
      })
      .catch(error => {
        console.log(error);
      })

    },
    create_hour: function (e) {
      e.preventDefault();


      var times = (parseInt(this.hour.to)-parseInt(this.hour.from))+1;

      for (var i = 0; i < times; i++) {
        var hour_time = parseInt(this.hour.from)+i;

        axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/locations/'+this.$route.params.id+'/business_hours', {
          'hour_day': this.hour.day,
          'hour_time': hour_time+":00"
        }, { headers: { Authorization: this.$store.getters.getToken }})
        .then(response => {
          console.log(response);
          this.get_data();
        })
        .catch(error => {
          error.response.data.errors.forEach(val => {
            this.$notify({
              group: 'alert',
              type: 'error',
              title: val,
            });
          })
        })

      }

    },
    delete_hour(hour) {
      if(confirm("Öffnungszeit wirklich löschen?")) {
        axios.delete(process.env.VUE_APP_BASE_API+'/v1/admin/locations/'+this.$route.params.id+'/business_hours/'+hour.id, { headers: { Authorization: this.$store.getters.getToken }})
        .then(() => {
          this.$notify({
            group: 'alert',
            type: 'success',
            title: 'Öffnungszeit erfolgeich gelöscht.',
          });
          this.get_data();
        })
      }
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
